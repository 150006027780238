import * as React from "react"
import { StaticImage } from "gatsby-plugin-image"
import UsaLayout from "../../components/usalayout"
import Seo from "../../components/seo"
import cx from "classnames"
import * as styles from "../../styles/pages/math.module.scss"
import * as Icons from 'react-feather'

const IndexPage = () => (
  <UsaLayout>
    <Seo title="Guides" />
    <div className="container_fixed pt-2">
      <h1>Create a Writing Task</h1>
      <p>From the Home tab, select the BoomMath banner to start creating projects for your class. <br />Select the class and then select the topic in a unit.</p>
      <section className={styles.stepList}>
        <div className={styles.step}>
          <h3 className={cx(styles.stepTitle, "mb-0", "h-small")}><span className={cx(styles.stepTitle_step, "txt-default")}>1</span> Select Your Topic</h3>
          <div className={styles.stepBlock}>
            <StaticImage src="../../images/math/r1/select-topic.png" alt="Select Topic" width={630} />
          </div>
        </div>
        <div className={styles.step}>
          <div>
            <h3 className={cx(styles.stepTitle, "mb-0", "h-small")}><span className={cx(styles.stepTitle_step, "txt-default")}>2</span> Choose Task Template</h3>
            <p className="mb-0">Choose the question you wish your students to complete.</p>
          </div>
          <div className={styles.stepBlock}>
            <div>
              <StaticImage src="../../images/math/r1/choose-task-template.png" alt="Sect your template" width={630} />
            </div>
            <div>
              <div className="panel-bordered mb-1">
                <h3 className="txt-default">Essential Questions</h3>
                <p>These are preloaded with the Topic Vocabulary and can use voting (StarCasting).</p>
              </div>
              <div className="panel-bordered">
                <h3 className="txt-default">Social Emotional Questions</h3>
                <p>These have no voting function as they are personal to each student.</p>
              </div>
            </div>
          </div>
        </div>
        <div className={styles.step}>
          <h3 className={cx(styles.stepTitle, "mb-0", "h-small")}><span className={cx(styles.stepTitle_step, "txt-default")}>3</span> Writing Task Settings</h3>
          <div className={styles.stepBlock}>
            <div>
              <StaticImage src="../../images/math/r1/start-writing.png" alt="Task settings and starting the writing phase" width={630} />
            </div>
            <div>
              <p>Optionally add guidance notes for students to follow.</p>
              <p>Remove or add mathematical vocabulary for your students to use in their written response.</p>
              <p><strong>Save any changes and click "Start Writing" to share this writing task with your students!</strong></p>
            </div>
          </div>
          <div className={styles.textBlock}>
            <div className="panel-muted">
              <h3 className="h-small mb-0">What is Star Casting?</h3>
              <p>Star Casting allows students to read anonymous responses from other students and vote on how effectively they have answered the question posed.</p>
            </div>
          </div>
        </div>
        <div className={styles.step}>
          <h3 className={cx(styles.stepTitle, "mb-0", "h-small")}><span className={cx(styles.stepTitle_step, "txt-default")}>4</span> Manage Writing</h3>
          <div className={styles.stepBlock}>
            <div>
              <StaticImage src="../../images/math/r1/manage-writing.png" alt="" width={630} />
            </div>
            <div>
              <p>Your students can now see and start writing.</p>
              <p>You can easily see which students have started and submitted their work.</p>
              <ul className={styles.defList}>
                <li><Icons.File /> Student has not started</li>
                <li><Icons.Edit3 /> Student has started (entry in draft)</li>
                <li><Icons.FileText /> Entry has been submitted</li>
              </ul>
            </div>
          </div>
          <div className={styles.stepBlock}>
            <div>
              <StaticImage src="../../images/math/r1/manage-entry.png" alt="" width={630} />
            </div>
            <div>
              <p>You can select a student to view their draft or submitted response.</p>
              <p>Use the feedback button to ask for edits and changes. <strong>Note: The student will now be able to edit their response.</strong></p>
              <p>We automatically award Gems to students, but you can award a bonus Gem for students that have made an extra effort or need encouragement.</p>
            </div>
          </div>
          <h4 className="h-small mb-0">What do my students see?</h4>
          <div className={styles.stepBlock}>
            <div>
              <StaticImage src="../../images/math/r1/students-write.png" alt="" width={630} />
            </div>
            <div>
              <p>When your students login, they select Math then tap into the writing task.</p>
              <p>As mathematical vocabulary is used, it will be highlighted in the word bank.</p>
              <p>A student can save their response as draft and return to complete it at a later time. When done, click HAND IT IN. The student's response will not be editable after it has been handed in.</p>
              <p><strong>We'll automatically award students Gems for handing in their work!</strong></p>
            </div>
          </div>
        </div>

        <div className={styles.step}>
          <h3 className={cx(styles.stepTitle, "mb-0", "h-small")}><span className={cx(styles.stepTitle_step, "txt-default")}>5</span> StarCasting</h3>
          <p>When the students have handed in their responses and you are happy to start the Starcasting, click end writing.</p>
          <div className={styles.stepBlock}>
            <div>
              <StaticImage src="../../images/math/r1/start-voting.png" alt="" width={630} />
            </div>
            <div>
              <p>You have the option now to exclude any pieces of work from being visible in the voting phase and also whether or not they can win.</p>
              <ul className={styles.defList}>
                <li>
                  <Icons.Eye/><Icons.EyeOff />
                  Include or exclude from voting
                </li>
                <li>
                  <Icons.CheckCircle /><Icons.Slash />
                  When included, decide if it can win
                </li>
              </ul>
              <p>Students will not be aware of their entry's voting status.</p>
            </div>
          </div>
          <div className="panel-muted">
            <p className="mb-0"><strong>Warning: Once StarCasting begins you will not be able to edit the voting settings or re-open writing.</strong></p>
          </div>
          <h4 className="h-small">What do my students see?</h4>
          <div className={styles.stepBlock}>
            <div>
              <StaticImage src="../../images/math/r1/placeholder.png" alt="" width={630} />
            </div>
            <div>
              <p>Students see a randomized entry and will be asked to rate the writing response based on a 5 star system.</p>
              <p>Students will then continue to the next entry until they have voted on all the responses.</p>
              <p>Students will not see their own response.</p>
            </div>
          </div>
        </div>

        <div className={styles.step}>
          <h3 className={cx(styles.stepTitle, "mb-0", "h-small")}><span className={cx(styles.stepTitle_step, "txt-default")}>5</span> Announcing The Winner</h3>
          <div className={styles.stepBlock}>
            <div>
              <StaticImage src="../../images/math/r1/placeholder.png" alt="" width={630} />
            </div>
            <div>
              <p>When you are satisfied that enough voting has taken place, you can end StarCasting.</p>
              <p>You'll be able to preview the results and either re-open voting or announce the winner.</p>
            </div>
          </div>
        </div> 

        {/* template
        <div className={styles.step}>
          <h3 className={cx(styles.stepTitle, "mb-0", "h-small")}><span className={cx(styles.stepTitle_step, "txt-default")}>X</span> Title Here</h3>
          <div className={styles.stepBlock}>
            <div>
              <StaticImage src="../../images/math/r1/placeholder.png" alt="" width={630} />
            </div>
          </div>
        </div> 
        */}

      </section>

    </div>
  </UsaLayout>
)

export default IndexPage