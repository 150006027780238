import * as React from "react"
import { StaticImage } from "gatsby-plugin-image"
import PropTypes from "prop-types"
import { useStaticQuery, graphql, Link } from "gatsby"
import * as Icon from "react-feather"

import "../styles/main.scss"

const UsaLayout = ({children}) => {
  return (
    <>
    <header className="header">
      <div className="header_main">
        <a href={process.env.TEACHER_APP_URL} className="header_back">
          <Icon.ArrowLeftCircle />
          <StaticImage src="../images/boom-icon.png" alt="Boom Help" width={100} className="header_logo" />
          Back to App
        </a>
        <span className="txt-heavy">Help & Resources</span>
      </div>
      <nav className="header_nav">
        <Link to="/math" className="header_nav_link">Getting Started</Link>
      </nav>
    </header>
    <div className="layout">{children}</div>
    </>
  )
}

UsaLayout.propTypes = {
  children: PropTypes.node.isRequired,
}
export default UsaLayout